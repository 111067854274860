import React, { FC, useState, useEffect } from 'react'
import { Autocomplete } from '@material-ui/lab'
import {
  TextField,
  Switch,
  FormControlLabel,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  makeStyles,
  CircularProgress,
  FormGroup,
  Grid,
  Theme,
} from '@material-ui/core'
import SnackbarContent from 'components/creative-tim/Snackbar/SnackbarContent.js'
import {
  Categories,
  categorySchema,
  categoryTag,
  managedSchema,
  PaymentFees,
  VatRates,
  vatRateSchema,
} from './Models'
import CurrencyInput from 'components/currency-input/input'
import useLocalStorage from 'hooks/useLocalStorage'
import useSessionStorage from 'hooks/useSessionStorage'
import loadable from '@loadable/component'
import * as Sentry from '@sentry/react'
import fetch from 'libs/fetchWrapper'
import * as z from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import classNames from 'classnames'
import PaymentDropdown from 'components/payment/paymentDropdown'

const Button = loadable(
  () => import('components/creative-tim/CustomButtons/Button'),
  {
    fallback: <CircularProgress disableShrink />,
  }
)

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: 130,
    paddingRight: 10,
  },
  hideManaged: {
    display: 'none',
  },
  paymentDropdown: {
    marginTop: theme.spacing(1.75),
  },
}))

export interface IProps {
  onCalculate?: (model: IModel) => void
  calProcessing?: boolean
  managed?: boolean
}

const schema = z.object({
  category: categorySchema,
  soldPrice: z.number(),
  purchasePrice: z.number().optional(),
  shippingCharge: z.number().optional(),
  shippingCost: z.number().optional(),
  shopSelected: z.string().optional(),
  topRated: z.boolean().optional(),
  vatRegistered: z.boolean().optional(),
  itemVatRate: vatRateSchema.optional().nullable(),
  isManaged: managedSchema,
})

type schemaModel = z.infer<typeof schema>
export type IModel = Omit<schemaModel, 'isManaged'> & {
  managed: boolean
  payment?: PaymentFees
}

const BusinessSellerForm: FC<IProps> = ({
  onCalculate,
  calProcessing = false,
  managed = true,
}) => {
  const classes = useStyles()

  const {
    handleSubmit,
    errors,
    control,
    register,
    reset,
    setValue,
  } = useForm<schemaModel>({
    resolver: zodResolver(schema),
  })

  const [isError, setIsError] = useState(false)
  const [categoriesLoaded, setCategoriesLoaded] = useState(false)
  const [categories, setCategories] = useSessionStorage<Categories[]>(
    'businessCategories',
    []
  )
  const [managedCategories, setManagedCategories] = useSessionStorage<
    Categories[]
  >('managedBusinessCategories', [])
  const [
    categorySelected,
    setCategorySelected,
  ] = useLocalStorage<Categories | null>('categorySelectedBusiness', null)
  const [vatRatesLoaded, setVatRatesLoaded] = useState(false)
  const [vatRates, setVatRates] = useSessionStorage<VatRates[]>('vatRates', [])
  const [
    vatRatesSelected,
    setVatRatesSelected,
  ] = useLocalStorage<VatRates | null>('vatRateSelected', null)
  const [soldPrice, setSoldPrice] = useSessionStorage<number | undefined>(
    'soldPrice',
    undefined
  )
  const [shippingCharge, setShippingCharge] = useSessionStorage<
    number | undefined
  >('shippingCharge', undefined)
  const [shippingCost, setShippingCost] = useSessionStorage<number | undefined>(
    'shippingCost',
    undefined
  )
  const [purchasePrice, setPurchasePrice] = useSessionStorage<
    number | undefined
  >('purchasePrice', undefined)
  const [shopSelected, setShopSelected] = useLocalStorage('shopSelected', '')
  const [topRated, setTopRated] = useLocalStorage<boolean>('topRated', false)
  const [vatRegistered, setVatRegistered] = useLocalStorage<boolean>(
    'vatRegistered',
    false
  )
  const [
    paymentSelected,
    setPaymentSelected,
  ] = useLocalStorage<PaymentFees | null>('paymentSelected', null)

  const optionalSuffixLabel = vatRegistered ? 'ex VAT' : 'inc VAT'

  useEffect(() => {
    if (managed) return
    if (categories.length > 0) {
      setCategoriesLoaded(true)
      return
    } else {
      setCategoriesLoaded(false)
    }
    const abortController = new AbortController()

    fetch(`${process.env.GATSBY_API_URL}/categories/business`, {
      signal: abortController.signal,
    })
      .then(res => res.json())
      .then((result: Categories[]) => {
        result.forEach(r => categorySchema.parse(r))
        if (!abortController.signal.aborted) {
          setCategories(result)
          setCategoriesLoaded(true)
          setIsError(false)
        }
      })
      .catch(reason => {
        Sentry.captureException(reason)
        if (!abortController.signal.aborted) {
          setIsError(true)
        }
      })

    return () => {
      abortController.abort()
    }
  }, [managed, categories.length])

  useEffect(() => {
    if (!managed) return
    if (managedCategories.length > 0) {
      setCategoriesLoaded(true)
      return
    } else {
      setCategoriesLoaded(false)
    }
    const abortController = new AbortController()

    fetch(`${process.env.GATSBY_API_URL}/categories/managed-business`, {
      signal: abortController.signal,
    })
      .then(res => res.json())
      .then((result: Categories[]) => {
        result.forEach(r => categorySchema.parse(r))
        if (!abortController.signal.aborted) {
          setManagedCategories(result)
          setCategoriesLoaded(true)
          setIsError(false)
        }
      })
      .catch(reason => {
        Sentry.captureException(reason)
        if (!abortController.signal.aborted) {
          setIsError(true)
        }
      })

    return () => {
      abortController.abort()
    }
  }, [managed, managedCategories.length])

  useEffect(() => {
    if (!vatRegistered) return
    if (vatRates.length > 0) {
      setVatRatesLoaded(true)
      return
    }
    const abortController = new AbortController()

    fetch(`${process.env.GATSBY_API_URL}/vatRates`, {
      signal: abortController.signal,
    })
      .then(res => res.json())
      .then((result: VatRates[]) => {
        result.forEach(r => vatRateSchema.parse(r))
        if (!abortController.signal.aborted) {
          setVatRates(result)
          setVatRatesLoaded(true)
          setIsError(false)
        }
      })
      .catch(reason => {
        Sentry.captureException(reason)
        if (!abortController.signal.aborted) {
          setIsError(true)
        }
      })

    return () => {
      abortController.abort()
    }
  }, [vatRegistered, vatRates.length])

  useEffect(() => {
    reset({ isManaged: { managed: managed, payment: paymentSelected } })
  }, [managed])

  useEffect(() => {
    if (categorySelected) {
      const category = managed
        ? managedCategories.find(x => x.Name === categorySelected.Name)
        : categories.find(x => x.Name === categorySelected.Name)
      if (category) {
        setValue('category', category, { shouldDirty: true })
      } else {
        setValue('category', undefined, { shouldDirty: true })
      }
    }
  })

  const onSubmit = (data: schemaModel) => {
    const { isManaged, ...rest } = data
    const payment = isManaged.managed
      ? undefined
      : isManaged.payment
      ? isManaged.payment
      : undefined

    onCalculate &&
      onCalculate({
        ...rest,
        shopSelected: data.shopSelected === '' ? undefined : data.shopSelected,
        topRated: data.topRated || undefined,
        vatRegistered: data.vatRegistered || undefined,
        itemVatRate: data.itemVatRate || undefined,
        managed: isManaged.managed,
        payment: payment,
      })
  }

  const shopClasses = classNames({
    [classes.formControl]: true,
    [classes.hideManaged]: managed,
  })
  const paymentClasses = classNames({
    [classes.paymentDropdown]: true,
    [classes.hideManaged]: managed,
  })
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isError ? (
        <SnackbarContent
          message={
            <span data-testid="error">
              <b>Error:</b> Something went wrong while retriving categories
            </span>
          }
          close
          color="danger"
          icon="info_outline"
        />
      ) : null}
      <Controller
        control={control}
        name="category"
        defaultValue={categorySelected}
        render={({ onChange, ref, value }) => (
          <Autocomplete
            data-testid="bCategories"
            loading={!categoriesLoaded}
            options={managed ? managedCategories : categories}
            groupBy={option => option.Parent || option.Name}
            getOptionLabel={option => option.Name}
            getOptionSelected={option => option.Name === value?.Name}
            multiple={false}
            value={value}
            onChange={(_event, value) => {
              setCategorySelected(value)
              onChange(value)
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Categories"
                variant="outlined"
                error={!!errors.category}
                helperText={
                  value
                    ? categoryTag`${value}${shopSelected !== ''}`
                    : 'Required'
                }
                inputRef={ref}
              />
            )}
          />
        )}
      />
      <div className={paymentClasses}>
        <Controller
          control={control}
          name="isManaged"
          defaultValue={{ managed: managed, payment: paymentSelected }}
          render={({ onChange, ref, value }) => (
            <PaymentDropdown
              required
              initialValue={value.payment ? value.payment : null}
              fetchOnLoad={!managed}
              onChange={value => {
                setPaymentSelected(value)
                onChange({ managed: managed, payment: value })
              }}
              inputRef={ref}
            />
          )}
        />
      </div>
      <Grid container>
        <Grid item xs>
          <Controller
            control={control}
            name="soldPrice"
            defaultValue={soldPrice}
            render={({ onChange, ref, value }) => (
              <CurrencyInput
                label="Sold Price"
                data-testid="bSoldPrice"
                required
                initialValue={value}
                onChange={value => {
                  setSoldPrice(value)
                  onChange(value)
                }}
                inputRef={ref}
              />
            )}
          />
        </Grid>
        <Grid
          item
          md={4}
          xs={6}
          style={{ display: vatRegistered ? undefined : 'none' }}
        >
          <Controller
            control={control}
            name="itemVatRate"
            defaultValue={vatRatesSelected}
            render={({ onChange, ref, value }) => (
              <Autocomplete
                data-testid="bVatRates"
                loading={!vatRatesLoaded}
                options={vatRates}
                getOptionLabel={option => option.Name}
                getOptionSelected={option => option.Name === value?.Name}
                multiple={false}
                value={value}
                onChange={(_event, value) => {
                  setVatRatesSelected(value)
                  onChange(value)
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Vat Rates"
                    variant="outlined"
                    helperText={value ? `${value.Rate}%` : undefined}
                    inputRef={ref}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
      <Controller
        control={control}
        name="purchasePrice"
        defaultValue={purchasePrice}
        render={({ onChange, ref, value }) => (
          <CurrencyInput
            label="Purchase Price"
            data-testid="bPurchasePrice"
            showOptionalLabel
            optionalLabelSuffix={optionalSuffixLabel}
            initialValue={value}
            onChange={value => {
              setPurchasePrice(value)
              onChange(value)
            }}
            inputRef={ref}
          />
        )}
      />
      <Controller
        control={control}
        name="shippingCharge"
        defaultValue={shippingCharge}
        render={({ onChange, ref, value }) => (
          <CurrencyInput
            label="Shipping Charge"
            data-testid="bShippingCharge"
            showOptionalLabel
            initialValue={value}
            onChange={value => {
              setShippingCharge(value)
              onChange(value)
            }}
            inputRef={ref}
          />
        )}
      />
      <Controller
        control={control}
        name="shippingCost"
        defaultValue={shippingCost}
        render={({ onChange, ref, value }) => (
          <CurrencyInput
            label="Shipping Cost"
            data-testid="bShippingCost"
            showOptionalLabel
            optionalLabelSuffix={optionalSuffixLabel}
            initialValue={value}
            onChange={value => {
              setShippingCost(value)
              onChange(value)
            }}
            inputRef={ref}
          />
        )}
      />
      <FormGroup row={true}>
        <FormControl variant="outlined" className={shopClasses}>
          <InputLabel htmlFor="ebay-shop-select">eBay Shop</InputLabel>
          <Controller
            control={control}
            name="shopSelected"
            defaultValue={shopSelected}
            render={({ onChange, ref, value }) => (
              <Select
                value={value}
                data-testid="eBayShopSelect"
                inputRef={ref}
                label="eBay Shop"
                inputProps={{
                  name: 'eBay Shop',
                  id: 'ebay-shop-select',
                  'data-testid': 'eBayShopInputProps',
                }}
                onChange={event => {
                  setShopSelected(event.target.value as string)
                  onChange(event.target.value)
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Basic">Basic</MenuItem>
                <MenuItem value="Featured">Featured</MenuItem>
                <MenuItem value="Anchor">Anchor</MenuItem>
              </Select>
            )}
          />
        </FormControl>

        <FormControlLabel
          control={
            <Switch
              name="vatRegistered"
              checked={vatRegistered}
              onChange={(_event, checked) => {
                setVatRegistered(checked)
              }}
              inputRef={register}
            />
          }
          label="Vat Registered"
        />
        <FormControlLabel
          control={
            <Switch
              name="topRated"
              checked={topRated}
              onChange={(_event, checked) => {
                setTopRated(checked)
              }}
              inputRef={register}
            />
          }
          label="eBay Top Rated"
        />
      </FormGroup>
      <Button
        type="submit"
        disabled={calProcessing}
        data-testid="bCalculate"
        color="danger"
        size="lg"
        loading={calProcessing}
      >
        Calculate
      </Button>
    </form>
  )
}

export default BusinessSellerForm
